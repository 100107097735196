import { StylesConfig } from "react-select";

export const colourStyles: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'28px', overflowX:'auto', minWidth:'150px', maxWidth:'300px', fontSize:'12px', position:'relative', zIndex:'20'}),
}

export const colourStyles1: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'28px', overflowX:'auto', width:'120px', maxWidth:'400px', fontSize:'12px', position:'relative', zIndex:'20'}),
}

export const colourStyles2: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'36px', overflowX:'auto',}),
}

export const colourStyles3: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'28px', overflowX:'auto', minWidth:'150px', maxWidth:'400px', fontSize:'12px', position:'relative', zIndex:'99999'}),
}

export const colourStyles4: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'24px', overflowX:'auto', minWidth:'120px', maxWidth:'450px', fontSize:'12px', position:'relative', zIndex:'99999'}),
}

export const colourStyles5: StylesConfig<ColourOption, true> = {
	control: (styles) => ({ ...styles, backgroundColor: 'white', height:'24px', overflowX:'auto', minWidth:'80px', maxWidth:'150px', fontSize:'12px', position:'relative', zIndex:'99999'}),
}